import * as React from "react"
import Header from "../components/Header/Header";
import "./../sass/main.scss"

let lang = process.env.LANG;
let isFrench = lang === "FR";
let isLocal = ['dev', 'development'].includes(process.env.NODE_ENV);
let en_url = (isLocal ? "" : process.env.EN_URL) + "/en/";
let fr_url = (isLocal ? "" : process.env.FR_URL) + "/fr/";


const IndexPage = () => {
    return (
        <>
            <Header
                title={isFrench ? "Global Affairs Job Connector | Connecteur d'emploi des affaires mondiales" : "Global Affairs Job Connector | Connecteur d'emploi des affaires mondiales"}
                description={isFrench ? "Global Affairs Job Connector | Connecteur d'emploi des affaires mondiales" : "Global Affairs Job Connector | Connecteur d'emploi des affaires mondiales"}
                hidden={true} lang={lang} bodyClasses={"page-index splash"}/>

            <div id="bg" data-wb-randomize='{ "shuffle": true, "selector": "img", "toggle": "hide" }'>
                <img className="hide" src="https://www.canada.ca/content/dam/canada/splash/sp-bg-1.jpg"
                     alt="Splash Screen 1"/>
                <img className="hide" src="https://www.canada.ca/content/dam/canada/splash/sp-bg-2.jpg"
                     alt="Splash Screen 2"/>
                <img className="hide" src="https://www.canada.ca/content/dam/canada/splash/sp-bg-3.jpg"
                     alt="Splash Screen 3"/>
                <img className="hide" src="https://www.canada.ca/content/dam/canada/splash/sp-bg-4.jpg"
                     alt="Splash Screen 4"/>
                <img className="hide" src="https://www.canada.ca/content/dam/canada/splash/sp-bg-5.jpg"
                     alt="Splash Screen 5"/>
                <img className="hide" src="https://www.canada.ca/content/dam/canada/splash/sp-bg-6.jpg"
                     alt="Splash Screen 6"/>
                <img className="hide" src="https://www.canada.ca/content/dam/canada/splash/sp-bg-7.jpg"
                     alt="Splash Screen 7"/>
                <img className="hide" src="https://www.canada.ca/content/dam/canada/splash/sp-bg-8.jpg"
                     alt="Splash Screen 8"/>
                <img className="hide" src="https://www.canada.ca/content/dam/canada/splash/sp-bg-9.jpg"
                     alt="Splash Screen 9"/>
                <img className="" src="https://www.canada.ca/content/dam/canada/splash/sp-bg-10.jpg"
                     alt="Splash Screen 10"/>
                <img className="hide" src="https://www.canada.ca/content/dam/canada/splash/sp-bg-11.jpg"
                     alt="Splash Screen 11"/>
                <img className="hide" src="https://www.canada.ca/content/dam/canada/splash/sp-bg-12.jpg"
                     alt="Splash Screen 12"/>
            </div>
            <main property="mainContentOfPage" className="pb-5" resource="#wb-main" typeof="WebPageElement">
                <div className="sp-hb">
                    <div className="sp-bx col-xs-12">
                        <h1 property="name" className="wb-inv">Canada.ca</h1>
                        <div className="row">
                            <div className="col-xs-11 col-md-8" property="publisher" resource="#wb-publisher"
                                 typeof="GovernmentOrganization">
                                <img src="https://www.canada.ca/etc/designs/canada/wet-boew/assets/sig-spl.svg"
                                     width="283" alt="Government of Canada" property="logo"/><span
                                className="wb-inv"> / <span lang="fr">Gouvernement du Canada</span></span>
                                <meta property="name" content="Government of Canada"/>
                                <meta property="name" lang="fr" content="Gouvernement du Canada"/>
                                <meta property="areaServed" typeof="Country" content="Canada"/>
                            </div>
                        </div>
                        <div className={"row justify-content-center align-items-center"}>
                            <div className={"col-lg-6"}>
                                <h2 className={"text-right"} style={{border: "none"}}>Job Opportunities at our Canadian offices abroad</h2>
                                {/*<h3 className={"text-right"}>Find a job outside Canada with the Government of Canada.</h3>*/}
                                {/*<h3></h3>*/}
                            </div>
                            <div className={"col-lg-6 "}>
                                <h2 className={"text-left"} style={{border: "none"}}>Opportunités d’emploi dans nos bureaux du Canada à l’étranger</h2>
                                {/*<h3>Trouvez un emploi à l'extérieur du Canada avec le Gouvernement du Canada.</h3>*/}
                            </div>
                        </div>
                        <div className="row">
                            <section className="col-xs-6 text-right">
                                <div>
                                    <h2 className="wb-inv">Government of Canada</h2>

                                </div>
                                <p><a href={en_url} className="btn btn-primary">English</a></p>
                            </section>
                            <section className="col-xs-6" lang="fr">
                                <div>
                                    <h2 className="wb-inv">Gouvernement du Canada</h2>
                                </div>
                                <p><a href={fr_url} className="btn btn-primary">Français</a></p>
                            </section>

                        </div>
                    </div>
                    <div className="sp-bx-bt col-xs-12">
                        <div className="row">
                            <div className="col-xs-7 col-md-8">
                                <a href="https://www.canada.ca/en/transparency/terms.html" className="sp-lk">Terms and
                                    conditions</a> <span className="glyphicon glyphicon-asterisk"/> <a
                                href="https://www.canada.ca/fr/transparence/avis.html" className="sp-lk"
                                lang="fr">Avis</a>
                            </div>
                            <div className="col-xs-5 col-md-4 text-right mrgn-bttm-md">
                                <img src="https://www.canada.ca/etc/designs/canada/wet-boew/assets/wmms-spl.svg"
                                     width="127" alt="Symbol of the Government of Canada"/><span
                                className="wb-inv"> / <span
                                lang="fr">Symbole du gouvernement du Canada</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default IndexPage;
